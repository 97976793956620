<template>
  <div style="background: #ffffff">
    <NavHeader></NavHeader>
    <div class="de-box">
      <a href="http://localhost:8080/#/home">返回首页</a>
      <!-- 广告图 -->
      <div style="display: flex; justify-content: center; margin-top: 30px">
        <img
          src="@/assets/images/doubleEleven/dbl11_Banner.jpg"
          alt=""
          style="width: 1200px"
        />
      </div>
      <!-- 仪直播 -->
      <div class="de-title">
        <span class="de-title-important">仪直播 购多惠</span><span class="de-title-detail">明白购物 专享优惠 购物积分翻倍</span>
      </div>
      <el-row type="flex" justify="space-between" :gutter="57" class="de-live">
        <div>
          <el-col :span="24">
            <a href="https://live.861718.com/web/wap/#/pages/live/live?id=256">
            <div class="de-live-goods">
              <img src="../../assets/images/doubleEleven/dbl11_live_logo.png" alt="直播logo"
                style="position: absolute; left: 14px; top: 16px" />
            </div>
            <p style="
                color: #000000;
                font-size: 18px;
                margin: 13px 0 10px 0;
                font-weight: 600;
                text-align: center;
              ">
              优利德(UINI-T)官方直播间
            </p>
            <p style="color: #666666; font-size: 16px;text-align: center;">
              2022.11.11 15：00 优利德爆款产品限时促销
            </p>
            </a>
          </el-col>
        </div>
      </el-row>

      <!-- 仪臻品 -->
      <div class="de-title">
        <span class="de-title-important">仪臻品 购实在</span
        ><span class="de-title-detail">好货好价 积分翻倍</span>
      </div>
      <el-row
        class="de-commodity"
        style="padding: 24px 0 52px; width: 1200px; margin: auto"
        :gutter="54"
      >
        <div class="de-commodity-box">
          <el-col
            :span="4.8"
            v-for="(item, index) in this.deCommodity"
            :key="index"
          >
            <div
              class="de-commodity-bg"
              @click="$util.toDetail(item.shop_type, item.goods_id)"
            >
              <img
                :src="$config.baseUrl + item.files_path"
                alt="仪臻品"
                style="max-width:127px;max-height:127px"
              />
              <p
                style="
                  margin: 1px 0 4px;
                  font-size: 12px;
                  color: #666666;
                  font-weight: 500;

                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-overflow: -webkit-ellipsis-lastline;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  line-clamp: 3;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.goods_name }}
              </p>
              <p class="item-price1"><span>￥</span>{{ item.act_value }}</p>

              <!-- <p style="color: #d7000f; font-size: 14px">
                ￥<span style="font-size: 30px">159</span>
              </p> -->
              <!-- 价格 -->
              <!-- <div class="item-price1" v-if="item.isprice == 2">
                <p></p><span>￥</span>{{ parseFloat(item.min_price).toFixed(2) }}
                -
                <p></p><span>￥</span>{{ parseFloat(item.max_price).toFixed(2) }}
              </div>

              <div class="item-price1" v-if="item.isonly == 1 && item.isprice == 1">
                <span>￥</span>{{
                    parseFloat(item.sales_price / 100).toFixed(2)
                }}
              </div>
              <div class="item-price1" v-if="item.isonly == 2 && item.isprice == 1">
                <span>￥</span>{{ item.spec_min_price }} 起
              </div> -->
            </div>
          </el-col>
        </div>
        <div @click="commodityMore" class="deshowMore">查看更多产品</div>
      </el-row>

      <!-- 仪好店 -->
      <div class="de-title">
        <span class="de-title-important">仪好店 购安心</span
        ><span class="de-title-detail">精选好店 安心选择</span>
      </div>
      <div style="padding-bottom:10px">
      <el-row class="de-shop" :gutter="40">
        <!-- 外层循环 -->
        <el-col :span="12" v-for="(item, index) in shopList" :key="index">
          <div
            style="background: #e5e5e5; margin-bottom: 42px; padding-top: 19px"
          >
            <div
              style="
                height: 81px;
                background: #e8440a;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <img
                v-if="item.shop_logo"
                :src="item.shop_logo"
                alt="仪好店logo"
                style="
                  width: 123px;
                  height: 50px;
                  line-height: 81px;
                  position: absolute;
                  left: 14px;
                "
              />
              <img
                v-else
                src="../../assets/images/tx.jpg"
                alt=""
                style="
                  width: 123px;
                  height: 50px;
                  line-height: 81px;
                  position: absolute;
                  left: 14px;
                "
              />
              <span style="color: #ffffff; font-size: 20px; margin: auto">{{
                item.shop_name
              }}</span>
              <div
                class="de-shop-bt"
                @click="$util.toShop(item.shop_type, item.shop_id)"
              >
                进店
              </div>
              <div style="clear: both"></div>
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                position: relative;
                height: 220px;
              "
            >
              <div
                v-for="(good, gIndex) in item.get_goods"
                :key="gIndex"
                style="margin: 33px 14px 27px"
              >
                <img
                  :src="$config.baseUrl + good.good_url"
                  alt="仪好店"
                  style="width: 157px; height: 157px"
                />
                <!-- <p
                  style="
                    margin: 10px 0 14px;
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    width: 157px;
                    word-break: break-all;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-overflow: -webkit-ellipsis-lastline;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ good.goods_name }}
                </p> -->

                <!-- 价格 -->
                <!-- <div class="item-price1" v-if="good.isprice == 2">
                  <p></p>
                  <span>￥</span>{{ parseFloat(good.min_price).toFixed(2) }}
                  -
                  <p></p>
                  <span>￥</span>{{ parseFloat(good.max_price).toFixed(2) }}
                </div>

                <div
                  class="item-price1"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >
                  <span>￥</span
                  >{{ parseFloat(good.sales_price / 100).toFixed(2) }}
                </div>
                <div
                  class="item-price1"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥</span>{{ good.spec_min_price }} 起
                </div> --> 
              </div>
            </div>
          </div>
        </el-col>
        
        <el-col :span="12">
          <div
            style="background: #e5e5e5; margin-bottom: 42px; padding-top: 19px"
          >
            <div
              style="
                height: 81px;
                background: #e8440a;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <img
              v-if="this.deShopLogoPath40"
                :src="$config.baseUrl + this.deShopLogoPath40"
                alt="仪好店logo"
                style="
                  width: 123px;
                  height: 50px;
                  line-height: 81px;
                  position: absolute;
                  left: 14px;
                "
              />
              <img
                v-else
                src="../../assets/images/tx.jpg"
                alt=""
                style="
                  width: 123px;
                  height: 50px;
                  line-height: 81px;
                  position: absolute;
                  left: 14px;
                "
              />
              <span style="color: #ffffff; font-size: 20px; margin: auto">{{
                deShopName40
              }}</span>
              <div
                class="de-shop-bt"
                @click="$util.toShop('2,3,1', 40)"
              >
                进店
              </div>
              <div style="clear: both"></div>
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                position: relative;
                height: 220px;
              "
            >
              <div
              v-for="(good40, gIndex40) in deShopGoods40"
                :key="gIndex40"
                style="margin: 33px 14px 27px"
              >
                <img
                :src="$config.baseUrl  + good40.files_path"
                  alt="仪好店"
                  style="width: 157px; height: 157px"
                />
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="12">
          <div
            style="background: #e5e5e5; margin-bottom: 42px; padding-top: 19px"
          >
            <div
              style="
                height: 81px;
                background: #e8440a;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <img
              v-if="this.deShopLogoPath392"
                :src="$config.baseUrl + this.deShopLogoPath392"
                alt="仪好店logo"
                style="
                  width: 123px;
                  height: 50px;
                  line-height: 81px;
                  position: absolute;
                  left: 14px;
                "
              />
              <img
                v-else
                src="../../assets/images/tx.jpg"
                alt=""
                style="
                  width: 123px;
                  height: 50px;
                  line-height: 81px;
                  position: absolute;
                  left: 14px;
                "
              />
              <span style="color: #ffffff; font-size: 20px; margin: auto">{{
                deShopName392
              }}</span>
              <div
                class="de-shop-bt"
                @click="$util.toShop('2,3,1', 392)"
              >
                进店
              </div>
              <div style="clear: both"></div>
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                position: relative;
                height: 220px;
              "
            >
              <div
              v-for="(good392, gIndex392) in deShopGoods392"
                :key="gIndex392"
                style="margin: 33px 14px 27px"
              >
                <img
                :src="$config.baseUrl  + good392.files_path"
                  alt="仪好店"
                  style="width: 157px; height: 157px"
                />
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
      <div class="deshopMore" @click="$router.push('/flagShip')">
        查看更多店铺
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "@/components/layout/nav-header";
export default {
  components: {
    NavHeader,
  },
  data() {
    return {
      deShop: [],
      deCommodity: [],
      deCommodityMore: [],
      deCommodityLess: [],
      showdeCommodityMore: true,
      shopList: [],

      deShopLogoPath40:"",
      deShopLogoPath392:"",
      deShopName40:"",
      deShopName392:"",
      deShopGoods40:[],
      deShopGoods392:[],

      searchData: {
        brand_id: "",
        catid: "",
        shop_type: 3,
        page: 1,
        limit: 100,
      },
    };
  },
  created() {
    this.$get("home/specialActivity").then((res) => {
      console.log("shuangshiyi", res.items.data);
      this.deCommodity = res.items.data.slice(0, 30);
      this.deCommodityMore = res.items.data;
      this.deCommodityLess = res.items.data.slice(0, 30);
      this.deShopGoods40 = res.items.data.filter(apt=>apt.shop_id==40).slice(0,3)
      this.deShopGoods392 = res.items.data.filter(apt=>apt.shop_id==392).slice(0,3)
    });

    this.$get("/home/specialShop").then((res) => {
      this.deShopLogoPath40 = res.items[0].files[0].files_path
      this.deShopLogoPath392 = res.items[1].files[0].files_path
      this.deShopName40 = res.items[0].shop_name
      this.deShopName392 = res.items[1].shop_name

    });

    this.$get("/home/shop", this.searchData).then((res) => {
      this.shopList = res.items.data.slice(0, 6);
      res.items.data.forEach((item) => {
        item.get_goods = item.get_goods.filter((o, i) => {
          return i < 3;
        });
      });
    });
  },
  methods: {
    commodityMore(e) {
      if (this.showdeCommodityMore) {
        this.deCommodity = this.deCommodityMore;
        this.showdeCommodityMore = false;
        e.target.innerHTML = "已加载全部";
      } else {
        this.deCommodity = this.deCommodityLess;
        e.target.innerHTML = "查看更多产品";
        this.showdeCommodityMore = true;
      }
    },
  },
};
</script>

<style scoped>
.de-box {
  width: 1200px;
  margin: auto;
}

.de-title {
  border-bottom: 1px solid #e8440a;
  margin: 73px 0 41px;
  padding: 0 0 13px 0;
}

.de-title-important {
  color: #e8440a;
  font-size: 36px;
  font-weight: bold;
  margin-right: 40px;
}

.de-title-detail {
  color: #666666;
  font-size: 24px;
  font-weight: 400;
}

.de-live-goods {
  background: url("../../assets/images/doubleEleven/de_youlide.jpg") 100% 100% no-repeat;
  background-size: 100% ;
  width: 1200px;
  height: 250px;
  position: relative;
}

.de-commodity {
  background: url("../../assets/images/doubleEleven/dbl11_Goodsbg.jpg") 100%
    100%;
  background-size: cover;
}

.de-commodity-box {
  margin: auto;
  width: 1140px;
  display: flex;
  flex-wrap: wrap;
}
.de-commodity-bg {
  padding: 13px 21px 11px;
  background: #ffffff;
  width: 172px;
  height: 261px;
  margin-top: 10px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.de-shop-bt {
  width: 89px;
  height: 40px;
  border-radius: 19px;
  background: #ffffff;
  color: #000000;
  position: absolute;
  right: 16px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
}

.item-price1 {
  color: #d7000f;
  font-size: 24px;
  position: absolute;
  bottom: 20px;
}
.deshowMore {
  height: 50px;
  width: 200px;
  background: #ffffff;
  color: #e8440a;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  border-radius: 12px;
  margin: 40px auto 10px;
  cursor: pointer;
}
.deshopMore {
  height: 50px;
  width: 200px;
  background: #e8440a;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  border-radius: 12px;
  margin: 6px auto 80px;
  cursor: pointer;
}
</style>